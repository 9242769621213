<template>
  <div>
    <a href="https://apps.apple.com/us/app/わせジュール/id6479050214">
      <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1455840000"
        alt="Download on the App Store"
        style="
          border-radius: 13px;
          width: 160px;
          height: 83px;
          padding-right: 10px;
        "
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.wasedule.wase_dule&hl=ja"
      ><img
        alt="Google Play で手に入れよう"
        src="https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png"
        style="border-radius: 13px; width: 150px; height: 63px"
      />
    </a>
  </div>
</template>
