<template>
  <div>
    <v-card
      style="
        border-radius: 12px;
        margin: 15px auto;
        background-color: transparent;
        color: #f4f5f7;
      "
    >
      <h5 class="center" data-aos="fade-up" style="font-weight: 900">
        お問い合わせ・ご要望など
      </h5>
      <p>
        お問い合わせ等は下記のフォーム<br />または公式サポートメールアドレス<a
          href="mailto:support@wasedule.com"
          style="color: antiquewhite"
          >support@wasedule.com</a
        ><br />まで直接ご連絡ください。
      </p>

      <small
        >ご相談、ご質問いただいた内容に関しましては、直接又は個人情報を秘匿しながらわせジュール公式SNSにおいてお答えさせていただく場合がございます。</small
      >
    </v-card>

    <v-card
      id="5"
      style="
        padding-top: 15px;
        border-radius: 12px;
        margin: 15px auto;
        background-color: rgba(255, 255, 255, 0.04);
        color: #f4f5f7;
        border: 1px #f4f5f7 solid;
      "
      elevation="24"
    >
      <v-card-title
        style="border-bottom: 1px antiquewhite solid; font-weight: bold"
        >お問い合わせフォーム</v-card-title
      >

      <v-form ref="form" v-model="contactFormValidation.valid" lazy-validation>
        <v-row>
          <v-col cols="5">
            <v-label style="font-weight: bold; color: white"
              >お名前<br />（仮名でも可）</v-label
            >
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="contactForm.name"
              :rules="contactFormValidation.nameRules"
              required
              style="background-color: rgba(244, 245, 247, 0.5); color: black"
              hide-details="auto"
              rows="1"
              :density="true"
              variant="solo"
              placeholder="お名前を記入..."
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-label style="font-weight: bold"
              >メールアドレス<br />(空白でも可)</v-label
            >
          </v-col>
          <v-col cols="7">
            <v-text-field
              v-model="contactForm.email"
              style="background-color: rgba(244, 245, 247, 0.8); color: black"
              hide-details="true"
              rows="1"
              :density="true"
              variant="solo"
              placeholder="メールアドレスを記入..."
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-label style="font-weight: bold"
              >ご意見・ご要望を<br />お聞かせください</v-label
            >
          </v-col>
          <v-col cols="7">
            <v-textarea
              v-model="contactForm.contents"
              :rules="contactFormValidation.contentsRules"
              required
              placeholder="用件を記入..."
              :auto-grow="true"
              hide-details="auto"
              dark
              variant="solo"
              style="background-color: rgba(244, 245, 247, 0.5); color: black"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-label style="font-weight: bold"
              >公式SNSでの質問回答<br />を許可する<br />（個人情報は伏せます）</v-label
            >
          </v-col>
          <v-spacer cols="3"></v-spacer>
          <v-col cols="4">
            <v-checkbox-btn
              v-model="contactForm.checked"
              color="success"
            ></v-checkbox-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="6">
            <v-card-actions>
              <v-btn
                :loading="contactForm.loading"
                :disabled="!contactFormValidation.valid"
                @click="sendMail"
                block
                color="success"
                variant="outlined"
                elevation="24"
                class="mt-4 font-weight-bold"
                style="
                  background-color: rgba(244, 245, 247, 0.9);
                  position: relative;
                  border: #00796b 1px solid;
                "
              >
                <svg
                  id="plane"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  width="20"
                  height="20"
                  color="#00796b"
                  style="
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(-50%, -50%);
                  "
                  :class="{ animate: contactForm.isAnim }"
                >
                  <polygon
                    class="cls-637b8512f95e86b59c57a122-1"
                    points="22.5 3.43 2.5 11.05 18.69 20.57 22.5 3.43"
                  ></polygon>
                  <polyline
                    class="cls-637b8512f95e86b59c57a122-1"
                    points="13.21 17.34 9.17 20.57 8.54 14.6 21.55 4.38"
                  ></polyline>
                </svg>
                送信
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="3">
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-snackbar
      v-model="snackBar.show"
      :color="snackBar.color"
      bottom
      right
      :timeout="1000"
      class="font-weight-bold"
    >
      {{ snackBar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  data: () => ({
    contactForm: {
      name: "",
      contents: "",
      email: "",
      loading: false,
      checked: true,
      isAnim: false,
    },
    contactFormValidation: {
      valid: false,
      nameRules: [(v) => !!v || "名前は必須項目です"],
      contentsRules: [(v) => !!v || "内容は必須項目です"],
    },
    snackBar: {
      show: false,
      color: "",
      message: "",
    },
  }),
  methods: {
    sendMail() {
      if (this.$refs.form.validate()) {
        this.contactForm.isAnim = false;
        this.contactForm.loading = true;
        var sns_allow;
        if (this.contactForm.checked) {
          sns_allow = "SNSにおけるこの質問の利用を許可します";
        } else {
          sns_allow = "SNSにおけるこの質問の利用を許可しません";
        }
        emailjs
          .send(
            process.env.VUE_APP_EMAILJS_SERVICE_ID,
            process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
            {
              name: this.contactForm.name,
              email: this.contactForm.email,
              content: this.contactForm.contents,
              sns_allow: sns_allow,
            },

            {
              publicKey: process.env.VUE_APP_EMAILJS_PUBLIC_KEY,
            }
          )

          .then(() => {
            this.formReset();

            this.showSnackBar(
              "success",
              "お問い合わせありがとうございます。送信完了しました"
            );
            this.contactForm.isAnim = true;
          })
          .catch((err) => {
            this.showSnackBar(
              "error",
              "送信に失敗しました。時間をおいて再度お試しください"
            );
            console.log(err);
          })
          .finally(() => {
            this.contactForm.loading = false;
          });
      }
    },
    showSnackBar(color, message) {
      this.snackBar.message = message;
      this.snackBar.color = color;
      this.snackBar.show = true;
    },
    formReset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
div {
  margin: auto;
}

.cls-637b8512f95e86b59c57a122-1 {
  fill: aliceblue;
  stroke: currentColor;
  stroke-miterlimit: 9;
}
#plane {
  position: absolute;
  left: 0;
  top: 5;
  margin-left: 35px;
}

.animate {
  animation: flyAway 2s ease-out;
}

@keyframes flyAway {
  0% {
    transform: translate(0, -50%) rotate(0deg);
    visibility: visible;
  }

  100% {
    transform: translate(1500%, -800%) rotate(0deg);
    visibility: hidden;
  }
}
</style>
