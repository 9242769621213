<template>
  <v-app>
    <HeaderBar />
    <v-main>
      <router-view />
    </v-main>
    <FooterBar />
  </v-app>
</template>

<script>
import "sanitize.css";
import HeaderBar from "@/components/HeaderBar.vue";
import FooterBar from "@/components/FooterBar.vue";
//import maintenance from "@/views/MaintenancePage.vue";
export default {
  name: "app",
  components: {
    FooterBar,
    HeaderBar,
  },
};
</script>

<style src="../src/assets/css/sanitize.css"></style>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif, "游明朝", "YuMincho",
    "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "MS P明朝",
    "MS 明朝", Georgia, "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-field__input {
  margin-bottom: 0 !important;
}
</style>
