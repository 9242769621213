<template>
  <v-app-bar
    app
    absolute
    dark
    shrink-on-scroll
    dense
    fade-img-on-scroll
    id="header"
  >
    <router-link to="/" style="margin: auto auto; margin-left: 10px">
      <v-img
        :src="require('@/assets/img/favicon_reverse.png')"
        alt="ロゴ"
        style="width: 45px; height: 45px"
      />
    </router-link>

    <v-app-bar-title
      style="
        font-size: 20px;
        font-weight: 500;
        margin: 0 5px;
        padding: 0;
        height: auto;
      "
      >わせジュール</v-app-bar-title
    >
    <template v-slot:extension>
      <v-tabs align-with-title>
        <router-link to="/"
          ><v-tab style="font-weight: 600">ホーム</v-tab></router-link
        >

        <router-link to="/about"
          ><v-tab style="font-weight: 600">使い方</v-tab></router-link
        >

        <router-link to="/media"
          ><v-tab style="font-weight: 600">メディア</v-tab></router-link
        >
      </v-tabs>
    </template>
  </v-app-bar>
</template>
<script></script>

<style lang="scss" scoped>
$primary-color: #000;
$background-color: #f4f5f7;
$shadow-color: #434;
$highlight-color: #ccc;

#header {
  background-color: $background-color;
  color: $primary-color;
  text-align: left;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 4px 0 $shadow-color;
}
</style>
