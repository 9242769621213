<template>
  <div id="footer">
    <div>
      <p>&copy; 2024 わせジュール</p>
      <p>
        <router-link to="/privacy">プライバシーポリシー</router-link> |
        <a href="#">利用規約</a>
      </p>
    </div>
    <div style="margin-right: 20px; padding-right: 10px; padding-top: 10px">
      <v-icon>
        <div style="display: flex; justify-content: center">
          <a href="https://twitter.com/wasedule">
            <v-icon left right="true" color="#1DA1F2">mdi-twitter</v-icon>
          </a>
          <a
            href="https://www.instagram.com/wasedule/"
            style="margin-left: 5px"
          >
            <div id="instagram-icon">
              <v-icon left> mdi-instagram </v-icon>
            </div>
          </a>
        </div>
      </v-icon>
    </div>
  </div>
</template>

<style scoped>
#footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#footer a {
  color: #fff;
  text-decoration: none;
}

#footer a:hover {
  text-decoration: underline;
}
p {
  margin: 0;
  padding: 0;
}
#instagram-icon {
  background: linear-gradient(
    45deg,
    rgba(254, 212, 117, 1) 0%,
    rgba(229, 61, 93, 1) 50%,
    rgba(194, 49, 134, 1) 70%,
    rgba(156, 56, 187, 1) 100%
  );
  border-radius: 10px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: inline-block;
  font-weight: 400;
  padding: 0;
  margin: 0;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s linear;
  width: auto;
}
#instagram-icon:hover {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
}
</style>
